var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('q-permissions',{attrs:{"__PERMISSIONS__":_vm.permStatus,"page":'inventaire',"index":'store'}}),(_vm.permStatus)?_c('b-row',{staticClass:"position-relative table-responsive table-base"},[_c('b-col',{attrs:{"cols":"12","md":"9","xl":"8"}},[_c('b-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"m-auto"},[_c('b-card',[_c('section',[_c('b-row',[_c('b-col',[_c('b-col',{staticClass:"m-auto"},[_c('b-form-group',{attrs:{"label":"","label-for":"customer-name"}},[_c('label',[_vm._v(" Libellé de l'inventaire"),_c('span',{staticClass:"p-0 text-danger h6"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"libelle","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"customer-name","trim":"","state":errors.length > 0 ? false : null,"placeholder":"Emprunt de rénovation"},on:{"input":_vm.validateLibelle},model:{value:(_vm.libelle),callback:function ($$v) {_vm.libelle=$$v},expression:"libelle"}}),_c('small',{staticClass:"text-danger",class:_vm.valideLibelle ? 'block' : 'none'},[_vm._v(" Vous devez renseigner le libellé de la depense ")])]}}],null,false,3251482463)})],1)],1),_c('b-col',{staticClass:"m-auto",attrs:{"lg":"12"}},[_c('b-form-group',[_c('label',[_vm._v(" Date de l'inventaire "),_c('span',{staticClass:"p-0 text-danger h6"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null},on:{"input":_vm.validateDate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('small',{staticClass:"text-danger",class:_vm.valideDate ? 'block' : 'none'},[_vm._v(" Vous devez renseigner la date de l'inventaire' ")])]}}],null,false,3814878501)})],1)],1),_c('b-col',{staticClass:"m-auto",attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"","label-for":"register-libelle"}},[_c('label',[_vm._v(" Agence ")]),_c('validation-provider',{attrs:{"name":"montant","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Selectionnez une agence","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"libelle","state":errors.length > 0 ? false : null},model:{value:(_vm.agence),callback:function ($$v) {_vm.agence=$$v},expression:"agence"}})]}}],null,false,2690417354)})],1)],1)],1)],1),_c('h3',{staticClass:"m-2"},[_vm._v("Liste de l'article")]),_vm._l((_vm.duplicate),function(item,index){return _c('div',{key:index,staticClass:"bg-light p-1 mt-1",staticStyle:{"border-radius":"10px"}},[_c('div',{staticClass:"mb-1 d-flex justify-content-between border-bottom p-50"},[_c('span',[_vm._v("Detail de produit #"+_vm._s(index + 1))]),_c('div',{staticClass:"bg-white p-0 d-flex align-items-center justify-content-center cursor-pointer",staticStyle:{"border-radius":"100%","width":"25px","height":"25px"}},[_c('feather-icon',{attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.removeItem(index)}}})],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('b-row',{staticClass:"flex-grow-1"},[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('label',{},[_vm._v("Article "),_c('span',{staticClass:"text-danger h6"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"article","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-white",attrs:{"placeholder":"Selectionnez un article","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"libelle","options":_vm.articleList,"state":errors.length > 0 ? false : null},on:{"input":function($event){return _vm.validateArticle(index)}},model:{value:(item.article),callback:function ($$v) {_vm.$set(item, "article", $$v)},expression:"item.article"}}),_c('small',{staticClass:"text-danger",class:_vm.valideArticle ? 'block' : 'none'},[_vm._v(" Vous devez choisir un article' ")]),_c('small',{staticClass:"text-danger",class:_vm.validateDuplicate && _vm.qIndex === index ? 'block' : 'none'},[_vm._v(" Vous avez déjà choisir ce article' ")])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('label',{},[_vm._v("Quantité restante "),_c('span',{staticClass:"p-0 text-danger h6"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Quantite restante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-2",attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(item.qte),callback:function ($$v) {_vm.$set(item, "qte", $$v)},expression:"item.qte"}})]}}],null,true)})],1)],1)],1)])],1)}),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"id":"btnadd","size":"md","variant":"primary"},on:{"click":_vm.addNewItemInItemForm}},[_vm._v(" Ajouter un article ")])],1)],2)])],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3","xl":"4"}},[_c('b-card',{staticClass:"d-flex py-1",attrs:{"no-body":""}},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"d-flex w-100 mb-50 justify-content-center",attrs:{"variant":"primary","type":"submit","disabled":_vm.loading === true ? true : false},on:{"click":_vm.saveInventaire}},[(_vm.loading === true)?_c('div',{staticClass:"spinner-border text-primary"}):_c('span',[_vm._v("Ajouter")])]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"d-flex w-100 justify-content-center",attrs:{"variant":"outline-secondary"},on:{"click":_vm.retour}},[_vm._v(" Annuler ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }